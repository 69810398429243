import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiGl from "global/ApiGlobal";
import Api from "modEcomerce/ApiEcomerce";
import "./BrHeadCarroPop.css"
import HFormUser from "global/components/Page/Forms/HFormUser";
class BrHeadCarroPop extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
        console.log("data",this.data)
        //console.log("dxxxxxxxxxxxxx1", this.data.pDataBarra, this.data.dataObj)
        //console.log("dxxxxxxxxxxxxx2",this.data.dataObj.regCarro)
    }
    componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;

        var stJs = ''
        /*---------------------------------
            Off Canvas Function
        -----------------------------------*/
        stJs += '$body = $("body");'
        stJs += '(function() {'
        stJs += '    var $offCanvasToggle = $(".offcanvas-toggle"),'
        stJs += '        $offCanvas = $(".offcanvas"),'
        stJs += '        $offCanvasOverlay = $(".offcanvas-overlay"),'
        stJs += '        $mobileMenuToggle = $(".mobile-menu-toggle");'
        stJs += '    $offCanvasToggle.on("click", function(e) {'
        stJs += '        e.preventDefault();'
        stJs += '        var $this = $(this),'
        stJs += '            $target = $this.attr("href");'
        stJs += '        $body.addClass("offcanvas-open");'
        stJs += '        $($target).addClass("offcanvas-open");'
        stJs += '        $offCanvasOverlay.fadeIn();'
        stJs += '        if ($this.parent().hasClass("mobile-menu-toggle")) {'
        stJs += '            $this.addClass("close");'
        stJs += '        }'
        stJs += '    });'
        stJs += '    $(".offcanvas-close, .offcanvas-overlay").on("click", function(e) {'
        stJs += '        e.preventDefault();'
        stJs += '        $body.removeClass("offcanvas-open");'
        stJs += '         $offCanvas.removeClass("offcanvas-open");'
        stJs += '        $offCanvasOverlay.fadeOut();'
        stJs += '        $mobileMenuToggle.find("a").removeClass("close");'
        stJs += '    });'
        stJs += '})();'


        s.innerHTML = stJs;
        this.instance.appendChild(s);
    }
    eliminarUno = (idProducto, sumarResta) => {
        this.aplicarSumaResta(idProducto, -999)
    }
    aplicarSumaResta = (idProducto, sumarResta) => {
        this.post(this.data.pDataBarra.pAddCarro, { 'idKey': idProducto, 'sumaresta': sumarResta })
            .then(data => {
                window.location.href = ApiGl.comprarRoute
            })
    }
    render = () => (<>
        <div ref={el => (this.instance = el)} />
        <div id="offcanvas-cart" className="offcanvas offcanvas-cart">
            <div className="inner">
                <div className="head">
                    <span className="title">Carro de Compras</span>
                    <button className="offcanvas-close">×</button>
                </div>
                <div className="body customScroll">
                    <ul className="minicart-product-list">
                        {this.data.dataObj
                            && this.data.dataObj.regCarro
                            && this.data.dataObj.regCarro.map((producto, index) => {
                                return (<li key={'kio' + index}>
                                    <a onClick={() => this.props.navigate(Api.productoDetalleRoute + "/" + producto.idmodelo)}
                                        className="image">
                                        <img src={producto.http_imagen} alt={producto.nombre} />
                                    </a>
                                    <div className="content">
                                        <a onClick={() => this.props.navigate(Api.productoDetalleRoute + "/" + producto.idmodelo)}
                                            className="title">{producto.nombre}</a>
                                        <span className="quantity-price">{producto.cantidad} x <span className="amount">${producto.preciovta}</span></span>
                                        <span className="quantity-price"><span className="amount">${producto.preciototal}</span></span>
                                        <a onClick={() => { this.eliminarUno(producto.idmodelo) }} className="remove"><i className="fa fa-trash"></i></a>
                                    </div>
                                </li>
                                )
                            })}
                    </ul>
                </div>
                <div className="foot">
                    <div className="sub-total">
                        <table className="table">
                            <tbody>
                                <tr>
                                    <td className="text-left">Sub-Total</td>
                                    <td className="text-right">${this.data.dataObj && this.data.dataObj.regTotal && this.data.dataObj.regTotal[0].totalpagarformat}</td>
                                </tr>
                                {/*
                            <tr>
                                <td className="text-left">Eco Tax (-2.00) :</td>
                                <td className="text-right">£4.52</td>
                            </tr>
                            <tr>
                                <td className="text-left">VAT (20%) :</td>
                                <td className="text-right">£104.66</td>
                            </tr>
                            */}
                                <tr>
                                    <td className="text-left">Total :</td>
                                    <td className="text-right theme-color">${this.data.dataObj && this.data.dataObj.regTotal && this.data.dataObj.regTotal[0].totalpagarformat}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="buttons">
                        <a  onClick={() => this.props.navigate(ApiGl.comprarRoute)}
                            className="btn btn-dark btn-hover-primary mb-30px">Ver Carrito</a>
                        <a onClick={() => this.props.navigate(ApiGl.comprarFactRoute)}
                            className="btn btn-outline-dark current-btn">Comprar</a>
                    </div>
                    <p className="minicart-message">{/*Mensaje Al Final*/}</p>
                </div>
            </div>
        </div>


    </>
    )
}
export default WithNavigate(BrHeadCarroPop);  