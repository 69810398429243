import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import Api from "modEcomerce/ApiEcomerce";
import './CheckOutDatos.css';
import CheckOutDatosBilling from './CheckOutDatosBilling';
import CheckOutDatosOrder from './CheckOutDatosOrder';
import ShowLoading from "global/components/ShowLoading";
import HFormUser from "global/components/Page/Forms/HFormUser";
class CheckOutDatos extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
        this.state = {
            data: [],
            loading: true,
        };
    }

    componentDidMount() { this.readApi() }
    readApi = () => {
        this.post(Api.checkOut)
            .then(data => {
                this.setState({data: data, loading: false});
            })
    }

    render = () => {
        if (this.state.loading) return (<ShowLoading />)
        return (
            <div className="col-12">
                <div className="all-product-grid">
                    <div className="container1">
                        <div className="row">
                            <div className="col-lg-12 col-md-7">
                                <div id="checkout_wizard"
                                    className="checkout accordion left-chck145">
                                    <div className="checkout-step">
                                        <div className="pdpt-title">
                                            <h3>Datos de la Compra 2/4</h3>
                                        </div>
                                        {/*******************************************************************  */}


                                        <div className="checkout-area mb-60px">
                                            <div className="container1">
                                                <div className="container-inner">
                                                    <div className="row">
                                                        <CheckOutDatosBilling dataObj={this.state.data.dataObj} />
                                                        <CheckOutDatosOrder dataObj={this.state.data.dataObj} />
                                                        {/*<button  className="btn btn-success" onClick={this.data.onComunica}>Comunica</button> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*******************************************************************  */}`
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}
export default WithNavigate(CheckOutDatos);