import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import ApiAuth from "global/auth";
import ApiGl from "global/ApiGlobal";
import HFormUser from "global/components/Page/Forms/HFormUser";
import "./BrHeadCarroPopMob.css"
class BrHeadCarroPopMob extends HFormUser {
    constructor(props) {
        super(props);
        this.data = props;
        //console.log("this.data3333:",this.data)
    }




    componentDidMount() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.async = true;

        var stJs = ''
        /*---------------------------------
            Off Canvas Function
        -----------------------------------*/

        stJs += 'function mobileOffCanvasMenu() {'
        stJs += '    var $offCanvasNav = $(".offcanvas-menu, .overlay-menu"),'
        stJs += '        $offCanvasNavSubMenu = $offCanvasNav.find(".sub-menu");'

        /*Add Toggle Button With Off Canvas Sub Menu*/
        stJs += '    $offCanvasNavSubMenu.parent().prepend(\'<span className="menu-expand"></span>\');'

        /*Category Sub Menu Toggle*/
        stJs += '    $offCanvasNav.on("click", "li a, .menu-expand", function(e) {'
        stJs += '        var $this = $(this);'
        stJs += '       if ($this.attr("href") === "#" || $this.hasClass("menu-expand")) {'
        stJs += '            e.preventDefault();'
        stJs += '            if ($this.siblings("ul:visible").length) {'
        stJs += '                $this.parent("li").removeClass("active");'
        stJs += '                $this.siblings("ul").slideUp();'
        stJs += '                $this.parent("li").find("li").removeClass("active");'
        stJs += '                $this.parent("li").find("ul:visible").slideUp();'
        stJs += '            } else {'
        stJs += '                $this.parent("li").addClass("active");'
        stJs += '                $this.closest("li").siblings("li").removeClass("active").find("li").removeClass("active");'
        stJs += '                $this.closest("li").siblings("li").find("ul:visible").slideUp();'
        stJs += '                $this.siblings("ul").slideDown();'
        stJs += '            }'
        stJs += '        }'
        stJs += '    });'
        stJs += '}'
        stJs += 'mobileOffCanvasMenu();'


        s.innerHTML = stJs;
        this.instancemob.appendChild(s);
    }

    //white-bg
    render = () => (<>
        <div ref={el => (this.instancemob = el)} />
        {/*style={{ backgroundColor: this.data.regBar.html_bgcolor }}*/}
        <div className="mobile-header d-lg-none sticky-nav  ptb-20px"
            style={{ backgroundColor: 'blue' }}>
            <div className="container">
                <div className="row align-items-center">

                    <div className="col d-flex">
                        <div className="mobile-menu-toggle home-2">
                            <a href="#offcanvas-mobile-menu" className="offcanvas-toggle">
                                <svg viewBox="0 0 800 600">
                                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                                    <path d="M300,320 L540,320" id="middle"></path>
                                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                                </svg>
                            </a>
                        </div>
                        <div className="header-logo mt-7px">
                            <a onClick={() => this.props.navigate("/")}>
                                <img className="img-responsive"
                                    src={process.env.REACT_APP_URL_DATA + "/"
                                        + ApiAuth.getEmpresa()
                                        + "/img/logo_empresa.png"}
                                    alt="logo.png"
                                    width="40" height="40"
                                /></a>
                        </div>
                    </div>

                    <div className="col-auto">
                        <div className="header-tools justify-content-end">
                            <div className="cart-info d-flex align-self-center">
                                {this.data.pDataBarra &&
                                    (<a title="wishlist"
                                        onClick={() => this.props.navigate(ApiGl.deseosRoute)}
                                        className="heart offcanvas-toggle"
                                        data-number={this.data.dataObj && this.data.dataObj.regTotal && this.data.dataObj.regTotal[0].totheart}
                                    >
                                        <i className="icon-heart"></i></a>
                                    )
                                }
                                {/*style={{ color: this.data.regBar.html_txcolor }}*/}
                                {/*<span style={{ color: this.data.regBar.html_txcolor }}>*/}
                                {this.data.pDataBarra &&
                                    (<a title="cart"
                                        style={{ color: 'yellow' }}
                                        href="#offcanvas-cart"
                                        className="bag offcanvas-toggle"
                                        data-number={this.data.dataObj && this.data.dataObj.regTotal && this.data.dataObj.regTotal[0].totcarroitem}
                                    >
                                        <i className="icon-shopping-cart"></i>
                                        <span style={{ color: 'yellow' }}>
                                            {this.data.dataObj && this.data.dataObj.regTotal[0].totalpagarformat && this.data.dataObj.regTotal[0].totalpagarformat}
                                        </span>
                                    </a>
                                    )}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        {/****************************************************************** */}


        <div id="offcanvas-mobile-menu" className="offcanvas offcanvas-mobile-menu">
            <div className="inner customScroll">
                <div className="head">
                    <span className="title">&nbsp;</span>
                    <button className="offcanvas-close">×</button>
                </div>
                {this.data.pDataBarra &&
                    (<div className="offcanvas-menu-search-form">
                        <form action={this.data.pDataBarra.pCheckOutSearchRoute}>
                            <input type="text" placeholder="Search..." />
                            <button><i className="lnr lnr-magnifier"></i></button>
                        </form>
                    </div>
                    )

                }
                <div className="offcanvas-menu">
                    <ul>
                        {this.data.dataObj.regMenHead && this.data.dataObj.regMenHead.map((menuNiv00, index00) => {
                            if (menuNiv00.idpadre == -1 && index00 > -1)
                                return (<li key={index00}><span className="menu-expand"></span>
                                    <a onClick={() => this.props.navigate(menuNiv00.url)}>
                                        <span className="menu-text">
                                            {menuNiv00.descripcion}
                                        </span>
                                    </a>
                                    {(menuNiv00.hijos > 0)
                                        && (<ul className="sub-menu" style={{ display: "block" }}>
                                            {this.data.dataObj.regMenHead.map((menuNiv11, index11) => {
                                                if (menuNiv00.idmenu == menuNiv11.idpadre)
                                                    return (<li key={index11}>
                                                        <a onClick={() => this.props.navigate(menuNiv11.url)}>
                                                            <span className="menu-text">
                                                                {menuNiv11.descripcion}
                                                            </span>
                                                        </a>
                                                        {(menuNiv11.hijos > 0)
                                                            && (<ul className="sub-menu">
                                                                {this.data.dataObj.regMenHead.map((menuNiv22, index22) => {
                                                                    if (menuNiv11.idmenu == menuNiv22.idpadre)
                                                                        return (<li><a onClick={() => this.props.navigate(menuNiv22.url)}>
                                                                            {menuNiv22.descripcion}
                                                                        </a>
                                                                        </li>)
                                                                })}
                                                            </ul>)}
                                                    </li>
                                                    )
                                            })}
                                        </ul>)}
                                </li>)

                        })}

                    </ul>
                </div>
                {/*
            <div className="offcanvas-social mt-30px">
                <ul>
                    <li>
                        <a href="#"><i className="ion-social-facebook"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="ion-social-twitter"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="ion-social-google"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="ion-social-youtube"></i></a>
                    </li>
                    <li>
                        <a href="#"><i className="ion-social-instagram"></i></a>
                    </li>
                </ul>
            </div>
             */}
            </div>
        </div>



    </>
    )
}
export default WithNavigate(BrHeadCarroPopMob);