import React,{Component} from "react";
import { Route } from "react-router-dom";
import Api  from "modEcomerce/ApiEcomerce";
import ApiGl  from "global/ApiGlobal";
//import PrivateRoute from "global/auth/PrivateRoute";
import HMainRouter  from "global/components/Page/MenuLTE/HMainRouter";

import CategoryA from "modEcomerce/ventas/CategoriasA";
import CategoryB from "modEcomerce/ventas/CategoriasB";
import CategoryRedirect from "modEcomerce/ventas/CategoriasRedirect";
import Producto from "modEcomerce/ventas/components/producto/product_v3/Producto_v3";

import ApiTbk  from "global_tbk/ApiTbk";
import TbkPaidOut from "global_tbk/TbkCheckPaidOut";
import TbkCheckOut from "global_tbk/TbkCheckTbk";
import CheckOut from "modEcomerce/ventas/checkout/CheckOutPrd";
import DatosCompraDatos from "modEcomerce/ventas/checkout/CheckOutDatos";
import DatosCompraDesp from "modEcomerce/ventas/checkout/CheckOutDesp";
import DatosCompraFPay from "modEcomerce/ventas/checkout/CheckOutFPay";
import DatosFDespacho from "modEcomerce/ventas/despacho/FDespacho";


class MainRouterMod  extends Component{
      constructor(props) {
            super(props);
            this.data=props;
          }      
          //<Route exact path="/" element={<Home />} />
   render() {
      return (<HMainRouter>
                  <Route exact path="/" element={<CategoryA />} />
                  <Route exact path={Api.categoriaARoute} element={<CategoryA />} />
                  <Route exact path={Api.categoriaBRoute} element={<CategoryB />} />
                  <Route exact path={Api.categoriaRedirectRoute} element={<CategoryRedirect />} />
                  <Route exact path={ApiGl.deseosRoute} element={<CategoryA />} />
                  <Route path={Api.productoDetalleRoute +"/:idProducto"} element={<Producto />} />


                  <Route exact path={ApiTbk.tbkPagoRoute } element={<TbkPaidOut />} />
                  <Route exact path={ApiTbk.tbkCheckOutTokenRoute} element={<TbkCheckOut />} />
                  
                  <Route exact path={Api.checkOutRoute} element={<CheckOut />} />
                  <Route exact path={Api.checkOutDatoCompraFactRoute }  element={<DatosCompraDatos  onComunica={this.data.onComunica}/>} />
                  <Route exact path={Api.checkOutDatoCompraDespRoute }  element={<DatosCompraDesp  onComunica={this.data.onComunica}/>} />
                  <Route exact path={Api.checkOutFpayRoute }  element={<DatosCompraFPay  onComunica={this.data.onComunica}/>} />
                  
                  <Route exact path="/calendario"  element={<DatosFDespacho  onComunica={this.data.onComunica}/>} />
               </HMainRouter>
         );
      }
}
/*
<Route exact path={Api.checkOutDatoCompraFactRoute }  render={() => <DatosCompraDatos  onComunica={this.data.onComunica}/>} />
<Route exact path={Api.datosCompra+ "33"} element={<<DatosCompra />} />
<Route exact path={Api.datosCompra + "22"} element={<DatosCompra} />
*/
export default MainRouterMod;