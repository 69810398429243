import React, { Component } from 'react';
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import HFormUser from "global/components/Page/Forms/HFormUser";
import Api from "modEcomerce/ApiEcomerce";
import { Link } from 'react-router-dom';
import EstrellasRating from '../estrellas/EstrellasRating'
import Corazon from '../corazon/Corazon'
import "./Card_01_card.css"

class Card_03 extends HFormUser {
    constructor(props) {
        super(props);
        //this.data = props;
    }
    addCar(idModelo){
        this.data.addCar(this,idModelo)
    }

    render = () => {
        //console.log("productoData Card03:",this.data)
        return (<>
            <div key={"juh03_" + this.data.producto.idmodelo} className="primaryCard card text-white bg-success mb-3" >
                <div className="product-alerts">
                    {this.data.producto.dias_new && (<div className="product-new" >Nuevo</div>)}
                    {this.data.producto.dias_promo && (<div className="product-promotion" >Promocion</div>)}
                </div>
                <Corazon like={this.data.producto.swheart}
                    idModelo={this.data.producto.idmodelo}
                />
                <Link to={{
                    pathname: Api.productoDetalleRoute + "/" + this.data.producto.idmodelo
                    , state: { prueba: this.data.producto.idmodelo }
                    , key: this.data.producto.idmodelo
                }}
                    className="no-href-decoration"
                    style={{ width: '100%', height: '100%' }}
                >
                    <div className="secondayCard" >
                        <div className="product-content" >
                            {false && (<small className="product-old-price text-decoration-line-through text-secondary"
                                style={this.data.producto.precio_old !== 0 ? { opacity: 1 } : { opacity: 0 }} >
                                ${this.data.producto.precio_old}
                            </small>)}
                            <div className="product-title">
                                <b>{this.data.producto.titulo}</b>
                            </div>
                            <hr className="m-0" style={{ paddingTop: '0px', marginTop: '10px', marginBottom: '50px', width: '70%' }} />
                            <div className="editorWWW" style={{ marginLeft: '5px', marginRight: '5px', marginTop: '10px' }}
                                dangerouslySetInnerHTML={{ __html: this.data.producto.text_descrip }}>
                            </div>
                            {false && (<a href="/prueba1" className="card-link">Card link</a>)}
                            {false && (<a href="/prueba2" className="card-link">Another link</a>)}
                            <div className="product-price fw-light">${this.data.producto.precio_ven_display}</div>

                            <div className="product-stars mt-2">
                                <span>
                                    <EstrellasRating rating={this.data.producto.rating} />
                                </span>
                                <span className="product-amount-rating">({this.data.producto.calif_total}{this.data.producto.calif_total < 2 ? 'Calificación' : 'Calificaciones'})</span>
                            </div>
                        </div>
                    </div>
                </Link>
                <div className="product-add-card" onClick={() => this.addCar(this.data.producto.idmodelo)}>
                    <i className="fas fa-cart-plus"></i>
                    Agregar al Carro
                </div>
                {/*<div  className="product-add-card bg-warning" disabled>
        <i className="fas fa-cart-plus"></i>Carregando...
        <div className="m-0 ms-3 spinner-border text-primary spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
    </div>*/}
                {/*<div onClick={() => this.AddToCart()} data-bs-toggle="modal" data-bs-target="#staticBackdrop">{ insideCartBtn }</div>
        <div data-bs-toggle="modal" data-bs-target="#staticBackdrop"><div className="product-add-card"><i className="fas fa-cart-plus" aria-hidden="true"></i>Adicione ao Carrinho
        </div>
      </div>*/}
            </div>

        </>

        )
    }

}
export default WithNavigate(Card_03);
