import React,{Component} from 'react';
import Api from "modEcomerce/ApiEcomerce";
import HFormUser from "global/components/Page/Forms/HFormUser";
import "./Card_02_single.css"

class Card_05 extends HFormUser{
	constructor(props) {
     super(props);
     this.data=props;
  }
  loadClick=()=>{
    window.location.href=Api.productoDetalleRoute + "/"+this.data.producto.idmodelo
    if (this.data.clickLoad)
        this.data.clickLoad(this.data.producto.idmodelo)
  }
  //</div><div className="shop-bottom-area mt-35">
  render =()=>{
    return (<div className="col-12" style={{marginLeft:'10px',marginRight:'20px'}}>
                        <div className="tab-content jump">
                            <div id="shop-3" className="tab-pane  active">
                                <div className="shop-list-wrap scroll-zoom">
                                    <div className="slider-single-item">
                                        <div className="row list-product m-0px">
                                            <div className="col-md-12 padding-0px product-inner">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 p-0">
                                                        <div className="left-img">
                                                            <div className="img-block">
                                                                <a href="single-product.html" className="thumbnail">
                                                                    <img className="first-img" src={this.data.producto.http_imagen} alt="" />
                                                                    <img className="second-img" src={this.data.producto.http_imagen} alt="" />
                                                                </a>
                                                                <ul className="product-flag">
                                                                    <li className="new">new</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 p-0">
                                                        <div className="product-desc-wrap">
                                                            <div className="product-decs">
                                                                <h2><a href="single-product.html" className="product-link">{this.data.producto.titulo}EEEE</a></h2>
                                                                <div className="rating-product">
                                                                    <i className="ion-android-star"></i>
                                                                    <i className="ion-android-star"></i>
                                                                    <i className="ion-android-star"></i>
                                                                    <i className="ion-android-star"></i>
                                                                    <i className="ion-android-star"></i>
                                                                </div>
                                                                <div className="product-intro-info">
                                                                    <p>{this.data.producto.text_descrip}</p>
                                                                    <p>{this.data.producto.text_otro}</p>
                                                                    <p>{this.data.producto.text_packing}</p>
                                                                    <p>{this.data.producto.text_info}</p>
                                                                    
                                                                </div>
                                                            </div>
                                                            <div className="box-inner d-flex">
                                                                <div className="align-self-center">
                                                                    <div className="pricing-meta">
                                                                        <ul>
                                                                            <li className="current-price">${this.data.producto.precio_ven_display}</li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="in-stock">Availability: <span>299 In Stock</span></div>
                                                                    <div className="cart-btn">
                                                                        <a href="#" className="add-to-curt" title="Add to cart">Add to cart</a>
                                                                    </div>
                                                                    <div className="add-to-link">
                                                                        <ul>
                                                                            <li>
                                                                                <a href="wishlist.html" title="Add to Wishlist"><i className="icon-heart"></i></a>
                                                                            </li>
                                                                            <li>
                                                                                <a href="compare.html" title="Add to compare"><i className="icon-repeat"></i></a>
                                                                            </li>
                                                                            <li>
                                                                                <a className="quick_view" href="#" data-link-action="quickview" title="Quick view" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                                                    <i className="icon-eye"></i>
                                                                                </a>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
)}
  
}    
export default  Card_05;
