import React,{Component} from "react";
import WithNavigate from "global/components/Page/Forms/WithNavigate";
import { Navigate } from "react-router-dom";
import Api from "modEcomerce/ApiEcomerce";

import { Redirect } from 'react-router-dom';
class CategoriaRedirect extends Component {
    render = () => {
         return  <Navigate to={Api.categoriaARoute + ""+ this.props.location.search}  />
    }

}
export default WithNavigate(CategoriaRedirect);